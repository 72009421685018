<template>
  <div class="trial-content" :style="trial_content_css">
    <!-- HEADER -->
    <div v-if="hasHeader" class="header">
      <div class="more-info" :style="trial_header_css">More Info</div>
      <div class="header-name" :style="trial_header_css">{{ header_text }}</div>
    </div>

    <table>
      <tr>
        <th style="padding-top: 10px;padding-bottom: 10px;" width="35%">
          <div
            @click="openZoom"
            :id="trial.nct + '_' + this.trial.condition"
            class="qr"
          ></div>
        </th>
        <th class="tabla" width="65%">
          <p @click="onTrialClick" class="title">{{ trial.title }}</p>
        </th>
      </tr>
    </table>

    <div class="trial-footer" :style="trial_footer_css" @click="onTrialClick">
      <span>Phase: {{ trial.phase }}</span>
      <span style="margin-left:10px;">NCT: # {{ trial.nct }}</span>
    </div>

    <vue-modal-2
      :name="'modal-' + trial.nct + '_' + this.trial.condition"
      @on-close="closeZoom"
      noFooter
      noHeader
      style="z-index: 1000;"
    >
      <div
        @click="closeZoom"
        :id="'zoom' + trial.nct + '_' + this.trial.condition"
        class="qr-zoom"
      />
    </vue-modal-2>
  </div>
</template>

<script>
var qr = require("qr.js");

export default {
  name: "Clinicaltrial",
  data() {
    return {
      config: {
        new: {
          qrcolor: "#FDC932",
          text: "New Trials",
        },
        skin: {
          qrcolor: "#0E0F0E",
          text: "Skin Cancers",
        },
        head: {
          qrcolor: "#E01F1D",
          text: "Head and Neck Cancers",
        },
        gastro: {
          qrcolor: "#352D86",
          text: "Gastrointestinal Cancers",
        },
        lung: {
          qrcolor: "#0098D4",
          text: "Lung Cancers",
        },
        breast: {
          qrcolor: "#EA5892",
          text: "Breast Cancers",
        },
        gynecological: {
          qrcolor: "#00A69F",
          text: "Gynecological Cancers",
        },
        genito: {
          qrcolor: "#E8550D",
          text: "Genitourinary Cancers",
        },
        solid: {
          qrcolor: "#168C40",
          text: "Solid Tumors",
        },
        hema: {
          qrcolor: "#861262",
          text: "Hematologic Malignancies",
        },
        cns: {
          qrcolor: "#9C9B9B",
          text: "Central Nervous System Tumors",
        },
      },
    };
  },
  props: {
    trial: {
      type: Object,
      required: true,
    },
    hasHeader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    condition() {
      return this.trial.condition[0];
    },
    trial_header_css() {
      return "background-color: " + this.config[this.condition].qrcolor;
    },
    trial_footer_css() {
      var css = "background: " + this.config[this.condition].qrcolor;
      if (this.hasHeader) css = css + ";margin-top:-30px !important;";
      return css;
    },
    trial_content_css() {
      var color = this.config[this.condition].qrcolor;
      return {
        background: color,
        background:
          "linear-gradient(0deg, " + color + " 0%, rgba(244, 244, 244, 1) 40%)",
      };
    },
    header_text() {
      return this.config[this.condition].text;
    },
  },
  mounted() {
    var text = "https://clinicaltrials.gov/ct2/show/" + this.trial.nct;
    var qrcode = qr(text);

    var width = 200;
    var height = 200;

    var canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    var ctx = canvas.getContext("2d");

    var cells = qrcode.modules;

    var tileW = width / cells.length;
    var tileH = height / cells.length;

    for (var r = 0; r < cells.length; ++r) {
      var row = cells[r];
      for (var c = 0; c < row.length; ++c) {
        ctx.fillStyle = row[c] ? this.config[this.condition].qrcolor : "#fff";
        var w = Math.ceil((c + 1) * tileW) - Math.floor(c * tileW);
        var h = Math.ceil((r + 1) * tileH) - Math.floor(r * tileH);
        ctx.fillRect(Math.round(c * tileW), Math.round(r * tileH), w, h);
      }
    }
    canvas.style.width = "110px";
    $("#" + this.trial.nct + "_" + this.trial.condition).append(canvas);
    var zoomCanvas = this.cloneCanvas(canvas);
    zoomCanvas.style.width = "250px";
    $("#zoom" + this.trial.nct + "_" + this.trial.condition).append(zoomCanvas);
  },
  methods: {
    onTrialClick: function(event) {
      window.open(
        "https://clinicaltrials.gov/ct2/show/" + this.trial.nct,
        "_blank"
      );
    },
    openZoom() {
      this.$vm2.open("modal-" + this.trial.nct + "_" + this.trial.condition);
    },
    closeZoom() {
      this.$vm2.close("modal-" + this.trial.nct + "_" + this.trial.condition);
    },
    cloneCanvas(oldCanvas) {
      //create a new canvas
      var newCanvas = document.createElement("canvas");
      var context = newCanvas.getContext("2d");

      //set dimensions
      newCanvas.width = oldCanvas.width;
      newCanvas.height = oldCanvas.height;

      //apply the old canvas to the new one
      context.drawImage(oldCanvas, 0, 0);

      //return the new canvas
      return newCanvas;
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  display: flex;
}

.header .more-info {
  color: white;
  font-family: "Oswald", sans-serif;
  font-size: large;
  font-weight: bold;
  width: 250px;
  text-align: center;
  border-radius: 0 0 0 14px;
}

.header .header-name {
  color: white;
  font-family: "Oswald", sans-serif;
  font-size: large;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-left: 10px;
  border-radius: 0 14px 0 0;
}

.trial-content {
  width: 100%;
  height: 185px;
  margin-bottom: 10px;
}

.trial-footer {
  text-align: right;
  width: 60%;
  border-radius: 8px 0 0 0;
  padding-right: 7px;
  position: relative;
  left: calc(38%);
  margin-top: -3px;
}

.trial-footer span {
  font-family: "Oswald", sans-serif;
  font-size: medium;
  color: white;
}

.title {
  font-family: "Oswald", sans-serif;
  font-size: small;
  font-weight: normal;
  margin: 5px 5px 0 5px;
  color: rgb(80, 79, 79);
  cursor: pointer;
}

.qr {
  background: white;
  border-radius: 10px;
  padding: 16px 6px 10px 6px;
}

.qr-zoom {
  text-align: center;
  padding: 40px;
}

.tabla {
  text-align: justify;
  vertical-align: initial;
}
</style>
